const showBatteryPlatformHome = [6, 7, 15, 70, 73, 74, 75, 84, 10080]
const batteryIconArr = [
  'battery_gray_icon',
  'battery_green_icon',
  'battery_red_icon',
  'battery_external_icon'
]
const bgColorArr = ['grayBgColor', 'greenBgColor', 'redBgColor']

const showBattery = (data, platform) => {
  if (typeof data == 'string') {
    data = JSON.parse(data)
  }
  let batteryArr = []
  if (data) {
    if (data.bat1 != undefined) {
      data.BAT1 = data.bat1
      data.BAT2 = data.bat2
      data.BAT1VAL = data.bat1val
      data.BAT2VAL = data.bat2val
    }
    if (data.BAT1 != '' || data.BAT2 != '') {
      let batteryInfo = data
      if (showBatteryPlatformHome.includes(Number(platform))) {
        if (JSON.stringify(batteryInfo) != '{}') {
          let batteryInfoData = []
          if (typeof batteryInfo.BAT1 != 'undefined') {
            batteryInfoData.push({
              status: batteryInfo.BAT1,
              val: batteryInfo.BAT1VAL,
              index: 0
            })
          }
          if (platform != '70' && typeof batteryInfo.BAT2 != 'undefined') {
            batteryInfoData.push({
              status: batteryInfo.BAT2,
              val: batteryInfo.BAT2VAL,
              index: 1
            })
          }
          batteryInfoData.map((v, i) => {
            batteryArr.push(updataEachBatteryInfo(v))
          })
        } else {
          batteryArr.push({ img: '', batVal: '---' })
        }
      } else {
        batteryArr.push({ img: batteryIconArr[3] })
      }
    } else {
      batteryArr.push({ img: batteryIconArr[3] })
    }
  } else {
    // 不传参数的时候 用来重置电池显示
    batteryArr.push({ img: batteryIconArr[3] })
  }
  let newBatteryArr = JSON.parse(JSON.stringify(batteryArr))
  batteryArr.map(v => {
    if (v.img == 'battery_external_icon') {
      newBatteryArr = [v]
    }
  })
  return newBatteryArr
}
const updataEachBatteryInfo = itm => {
  let obj = {}
  let battery_status = itm.status
  let battery_val = parseInt(itm.val)
  if (battery_status == -1) {
    obj.img = batteryIconArr[3]
    obj.proColor = bgColorArr[1]
    battery_val = ''
  } else if (battery_val == 0 && battery_status != 2) {
    obj.img = batteryIconArr[0]
    obj.proColor = bgColorArr[0]
    battery_val += '%'
  } else if (battery_status != 2 && battery_val <= 20) {
    obj.img = batteryIconArr[2]
    obj.proColor = bgColorArr[2]
    battery_val = battery_val + '%'
  } else {
    obj.img = batteryIconArr[1]
    obj.proColor = bgColorArr[1]
    battery_val = battery_val + '%'
  }
  if (battery_status == 2) {
    obj.isCharge = true
  }
  battery_val = battery_val == 0 ? '' : battery_val
  obj.batVal = battery_val
  obj.batStatus = battery_status
  return obj
}

export default showBattery
